import React from 'react'
import styles from './index.module.scss'
import appstoreImg from '../images/appstore.svg';
import playstoreImg from '../images/playstore.svg';

const HomepageTemplate = ({
  html,
  title,
  subtitle,
  ctatitle,
  appstore,
  appstoreIcon,
  playstore,
  playstoreIcon,
  headerimage,
  supplementaryimage
}) => {

  const appStoreImagePath = appstoreIcon ? appstoreIcon : appstoreImg;
  const playStoreImagePath = playstoreIcon ? playstoreIcon : playstoreImg;
  
  return (
    <section className={ styles.homepage }>
      <div className={ styles.content }>
        <h1 className={ styles.mainHeading }>
          <img src={ headerimage } alt={ title } />
        </h1>
        <img className={ styles.supplementaryImg } src={ supplementaryimage } alt='' />

        <div className={ styles.textContent }>
          <h2 className={ styles.subtitle }>{ subtitle }</h2>
          <div className={ styles.body } dangerouslySetInnerHTML={{ __html: html }} />

          { appstore || playstore ? 
            <h2 className={ styles.ctaTitle}>{ ctatitle }</h2>
            : null
          }
          
          <div className={ styles.buttons }>
            { appstore ? 
              <a className={ styles.appstore } href={ appstore } target="_blank" rel="noopener noreferrer"><img src={ appStoreImagePath } alt="Apple App Store icon" /></a>
              : null
            }

            { playstore ? 
              <a className={ styles.playstore } href={ playstore } target="_blank" rel="noopener noreferrer"><img src={ playStoreImagePath } alt="Google Play Store icon" /></a>
              : null
            }
          </div>
        </div>
      </div>
    </section>
  )
}

export default HomepageTemplate
